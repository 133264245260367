<template>
  <div class="results">
    <img class="results-img" src="@/assets/images/icon_result.png" />
    <div class="results-tit">
      <slot name="title"></slot>
    </div>
    <div class="results-label">
      <slot name="label"></slot>
    </div>
    <div class="result-other">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Results",
};
</script>

<style lang="scss" scoped>
.results {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.4rem;
}
.results-img {
  display: block;
  width: 3.48rem;
  height: 2.72rem;
}
.results-tit {
  font-size: 0.48rem;
  font-weight: 500;
  color: #515151;
  line-height: 0.66rem;
  margin: 0.24rem 0;
}
.results-label {
  width: 4.48rem;
  opacity: 0.6;
  font-size: 0.28rem;
  text-align: center;
  color: #515151;
  line-height: 0.38rem;
}
.result-other {
  margin-top: 0.3rem;
}
</style>
