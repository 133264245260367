<template>
  <div class="result">
    <Results>
      <template #title>
        {{ $t("submitSuccess") }}
      </template>
      <template #label>
        {{ $t("officeResultLabel") }}
      </template>
    </Results>
  </div>
</template>

<script>
import Results from "@/components/Results";

export default {
  name: "result",
  components: {
    Results,
  },
};
</script>

<style lang="scss" scoped>
.result {
  padding-top: 2.76rem;
}
</style>
